@tailwind base;
@tailwind components;
@tailwind utilities;


.custom-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .custom-scrollbar-chat::-webkit-scrollbar {
    width: 10px;
  }
  
  .custom-scrollbar-chat::-webkit-scrollbar-thumb {
    background-color: #b1b2b3;
    border-radius: 10px;
  }
  
  .custom-scrollbar-chat::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .custom-scrollbar-chat::-webkit-scrollbar {
    width: 5px;
  }
  
  .custom-scrollbar-chat::-webkit-scrollbar-thumb {
    background-color: #b1b2b3;
    border-radius: 10px;
  }
  
  .custom-scrollbar-chat::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  /* Scroll */
  
  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #b1b2b3;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #b1b2b3;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .banner{
    background-image: url('/src/Assets/HomePage_Hero_small.png');
  }